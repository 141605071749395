body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /* Fallback */
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #141619 !important;
  color: #141619 !important;
  ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root,
html {
  background-color: #141619 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Unica One", sans-serif;
  color: white !important;
}

p {
  font-size: 1.1em;
  font-style: italic;
  opacity: 0.7;
  font-weight: 300;
  color: white;
}

ul,
pre,
li::marker {
  color: white;
}

code {
  color: yellowgreen !important;
  opacity: 1 !important;
}

#nav-brand {
  font-family: "Unica One", sans-serif;
  color: white;
}

#landing a:hover,
.icon:hover,
#nav-link:hover {
  border-bottom: 2px solid white;
  border-radius: 2px;
  text-decoration: none;
}

#landing a,
.icon,
#nav-link {
  color: white;
  text-decoration: none;
  font-size: x-large;
}

#landing,
#about,
#blog-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  border-bottom: 2px solid transparent;
}

#notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

a:hover,
.icon:hover {
  border-bottom: 2px solid white;
  border-radius: 2px;
}

a,
.icon,
#nav-link {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
}

.footer {
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer a {
  margin: 0 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: white;
  text-decoration: none;
}

@keyframes underline {
  0% {
    text-decoration: none;
  }

  50% {
    text-decoration: underline;
  }

  100% {
    text-decoration: none;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.post-date {
  font-family: "Unica One", sans-serif;
  color: white;
  opacity: 0.7;
  font-size: 0.6em;
}

.post-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
}

.custom-hr {
  margin: 0;
  border: none;
  border-bottom: 2px solid;
  position: relative;
  top: -5px;
}

.custom-pagination .page-item .page-link {
  background-color: #141619;
  /* Change background color */
  color: white;
  /* Change text color */
  border: none;
  /* Remove border */
  margin: 0 5px;
  /* Add margin between buttons */
}

.custom-pagination .page-item .page-link:hover {
  background-color: #141619;
  /* Change background color on hover */
  color: white;
  /* Change text color on hover */
}

.scrollbar {
  overflow: overlay
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 8px;
  height: 16px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}


/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

.navbar-light .navbar-toggler {
  border-color: white;
}

.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #FFF;
  left: -100px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
  }

  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }

  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
  }

  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
  }

  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }

  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}